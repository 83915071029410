import * as Vue from 'vue'
const routes = [
  {
    name: 'questionnaire.show',
    path: '/questionnaire',
    component: Vue.defineAsyncComponent(() => import('@/pages/questionnaire/Show.vue')),
    meta: {
      auth: true
    }
  }
]

export default routes
