const AccountTips = {
  1: [
    '亲爱的用户，您的海道教育个人账号因近期高频次多设备登录，被系统判别为高危账号，可能存在盗号风险。',
    '账号设备游离情况目前已引发后台服务器监控报警；严重时可直接触发后台，对于账号的保护机制，导致短时间冻结访问。平台出于对账号和个人信息保护原则，提醒您请即时更换登录密码，同时请勿在多台设备进行账号登录。'
  ],
  2: {
    top: ['亲爱的用户，经平台监测系统甄别发现，您的账号近期仍存在高频多设备登录的情况。该账号因涉及高频次多设备登录等违规操作，此行为违反《用户协议》中账户使用相关条例，触发平台账户保护机制，依照相关规定平台已对您平台账号做短期封禁处理。'],
    center: [],
    bottom: ['请严格遵守互联网平台账户使用规则及《用户协议》相关条例，如反复违规将影响账号正常使用；对本次违规如有疑问，请尽快与工作人员进行申诉处理，工作人员将会在7个工作日内对解封材料进行审核，并于通过后解除封禁。']
  },
  3: {
    top: ['亲爱的用户，近日通过HD EDUCATION用户举报，同时经监管小组核实，您的账号使用存在异常违规操作。此行为违反《用户协议》中第四条知识产权，依照我司对于侵犯课程产品知识产权的保护条例，平台将会对您在平台的账号进行封禁处理。'],
    center: ['封禁处置关联服务：移除学科社群及付费社群'],
    bottom: [
      '请勿将平台购买课程进行二次销售，并加强对互联网产品知识产权保护意识。',
      '如对本次违规存在疑问，请在7个工作日内与相关工作人员进行申诉处理，工作人员会在10-30个工作日内对您申诉材料进行复核处理。'
    ]
  },
  5: {
    top: [
      '亲爱的用户，您的账号使用存在异常违规操作。',
      '您的账号存在高频次课程购买情况，被系统监测判断为高危账号，可能存在盗号风险。出于对账号的保护，平台将会对您的账号做暂时冻结处理。'
    ],
    center: [],
    bottom: [
      '请勿将平台购买课程进行二次销售，并加强对互联网产品知识产权保护意识。',
      '如对本次违规操作存在疑问，请在7个工作日内与相关工作人员进行申诉处理，工作人员会在10-30个工作日内对您申诉材料进行复核，并对账号进行封禁解除处理。'
    ]
  },
  6: {
    top: [
      '亲爱的用户，您的账号使用存在异常违规操作。',
      '您的账号存在高频次课程购买情况，被系统监测判断为高危账号，可能存在盗号风险。出于对账号的保护，平台将会对您的账号做暂时冻结处理。'
    ],
    center: [],
    bottom: [
      '请勿将平台购买课程进行二次销售，并加强对互联网产品知识产权保护意识。',
      '如对本次违规操作存在疑问，请在7个工作日内与相关工作人员进行申诉处理，工作人员会在10-30个工作日内对您申诉材料进行复核，并对账号进行封禁解除处理。'
    ]
  },
  7: [
    '亲爱的用户，HD EDUCATION温馨提示，您的账号使用存在异常违规操作。经后台系统监测发现，您的个人账户因高频次进行课程购买情况，已被系统监测判断为高危账号，可能存在盗号风险。',
    '账号设备游离且进行多次课程购买情况目前已引发后台服务器监控报警；严重时可直接触发后台对于账号的保护机制，导致短时间冻结访问。平台处于对账号和个人信息保护原则，提醒您请及时更换登录密码'
  ],
  8: {
    top: [
      '尊敬的用户，您的个人账号因近期有涉及拼课违法行为，故对账号进行封禁处理。',
      '如有异议，请扫下方二维码联系课程部小助手进行申诉处理。'
    ],
    center: [],
    bottom: []
  }
}

export default AccountTips
