/* eslint-disable prefer-promise-reject-errors */
import isEmpty from 'lodash/isEmpty'
import localforage from 'localforage'
import {
  getUser,
  getTokenByCode,
  getTokenByPassword,
  getTokenByVerifyCode,
  registerUser,
  getFingerTip
} from '@api'
import { setToken as setHttpToken } from '@utils/http'
import { isWechatBrowser } from '@utils/helpers'
import Encrypt from '@/utils/encrypt'
import { tutorTeachTypes } from '@/utils/tutor'
import router from '@/router'

const state = {
  token: null,
  user: null,
  hasLogged: false,
  ban: {
    type: 0,
    time: null
  }
}

const getters = {
  authToken: ({ token }: any) => token,

  currentUser: ({ user }: any) => user,

  hasLogged: ({ hasLogged }: any) => hasLogged,

  userBan: ({ ban }: any) => ban,

  tutorType: ({ user }: any) => {
    const teacherTypes = user?.teacher_types ?? []
    const idsTeachTypes = teacherTypes.map((item: any) => item.id)
    return tutorTeachTypes(idsTeachTypes)
  }
}

const actions = {
  loadUser: ({ dispatch }: any) => getUser()
    .then(({ data }: any) => dispatch('setUser', data))
    .catch((response: any) => {
      if (response.status === 401) {
        dispatch('logout')
      }
    }),

  setUser ({ commit }: any, user: any) {
    commit('setUser', user)

    Promise.resolve(user)
  },

  setToken ({ commit }: any, token: string) {
    commit('setToken', token)

    setHttpToken(token)

    Promise.resolve(token)
  },

  setUserBan ({ commit }: any, ban: boolean) {
    commit('setUserBan', ban)

    Promise.resolve(ban)
  },

  setUserBanByInterval ({ dispatch }: any) {
    let time = 30
    const tipInterval = setInterval(async () => {
      const { data } = await getFingerTip()
      if (data.data && data.data.type > 0) {
        dispatch('setUserBan', Object.assign({
          type: 0,
          time: null
        }, data.data))
        time = 0
        clearInterval(tipInterval)
      }
      time--
      if (time === 0) {
        clearInterval(tipInterval)
      }
    }, 1000)
  },

  async checkUserToken ({ dispatch, commit, state }: any, params: any) {
    if (!isEmpty(state.token)) {
      commit('setLoginStatus', true)
      return Promise.resolve(state.token)
    }

    await localforage.getItem('auth.token').then((token) => {
      if (isEmpty(token)) {
        if (isWechatBrowser()) {
          if (params) {
            let redirectUrl = params.path

            if (params.query) {
              redirectUrl += `?${Object.keys(params.query).map(key => `${key}=${params.query[key]}`).join('&')}`
            }

            if (params.fullPath) {
              redirectUrl = params.fullPath
            }

            return router.push({
              name: 'auth.login',
              query: (params?.isTypeExperience || params.query?.type === 'experience') || params.fullPath
                ? { from: redirectUrl } // AI 测评体验
                : { path: encodeURIComponent(redirectUrl) }
            })
          }
          return Promise.reject('NO_TOKEN')
        } else {
          if (!['/auth/login/password', '/auth/login-width-phone'].includes(location.pathname)) {
            const isTypeExperience = params?.isTypeExperience ||
              params?.query?.type === 'experience'

            let url = `${
              import.meta.env.MODE === 'development'
                ? `${window.location.origin}/`
                : import.meta.env.VITE_APP_MOBILE_URL
            }auth/login${isTypeExperience ? '' : '/password'}`

            if (params && params.path) {
              url += `?from=${params.path}`
            }

            if (params?.query) {
              url += `?${new URLSearchParams(params.query).toString()}`
            }

            window.location.href = url
            return
          }

          return Promise.reject('NO_TOKEN')
        }
      }

      dispatch('setToken', token)
    })
      .then(() => dispatch('loadUser'))
  },

  async register ({ dispatch }: any, params: any) {
    const { sendData, encrypted } = await Encrypt(params)

    return registerUser({
      param1: sendData,
      param2: encrypted
    })
      .then(async ({ data }: any) => {
        await localforage.setItem('auth.token', data.token)
        dispatch('setUser', data.user)
        dispatch('setToken', data.token)

        return Promise.resolve(data.user)
      })
    // .then(() => {
    //   window.location.href = `${import.meta.env.VITE_APP_MOBILE_URL}`
    // })
  },

  async loginByVerifyCode ({ dispatch }: any, payload: any) {
    const { sendData, encrypted } = await Encrypt(payload.params)

    return getTokenByVerifyCode({
      param1: sendData,
      param2: encrypted
    }, payload.configs)
      .then(async ({ data }: any) => {
        await localforage.setItem('auth.token', data.token)
        dispatch('setUser', data.user)
        dispatch('setToken', data.token)

        return Promise.resolve(data.user)
      })
  },

  async loginByPassword ({ dispatch }: any, payload: any) {
    const { sendData, encrypted } = await Encrypt(payload.params)

    return getTokenByPassword({
      param1: sendData,
      param2: encrypted
    }, payload.configs)
      .then(async ({ data }: any) => {
        await localforage.setItem('auth.token', data.token)
        dispatch('setUser', data.user)
        dispatch('setToken', data.token)

        return Promise.resolve(data.user)
      })
  },

  async loginByCode ({ dispatch }: any, params: any) {
    let configs = {}
    const log = await localforage.getItem('UserLocal-Log')

    if (log) {
      configs = Object.assign(configs, {
        headers: {
          'UserLocal-Log': log
        }
      })
    }

    return getTokenByCode(params.code, configs)
      .then(async ({ data }: any) => {
        await localforage.setItem('auth.token', data.token)
        dispatch('setUser', data.user)
        dispatch('setToken', data.token)

        await localforage.setItem('finger-print', 0)

        await localforage.setItem('UserLocal-Log', data.user.id)

        dispatch('setUserBanByInterval')

        return Promise.resolve(data.user)
      })
      .catch(({ response }: any) => {
        return Promise.reject(response)
      })
  },

  logout: ({ dispatch }: any) => {
    return localforage
      .removeItem('auth.token')
      .then(dispatch('setToken', null))
      .then(dispatch('setUser', null))
  }
}

const mutations = {
  setToken (state: any, token: any) {
    state.token = token
    state.hasLogged = !!token
  },

  setUserBan (state: any, ban: any) {
    state.ban = ban
  },

  setLoginStatus (state: any, bool: boolean) {
    state.hasLogged = bool
  },

  setUser (state: any, user: any) {
    if (user) {
      const tutorFinancesMap: any = {}

      if (user.tutor_finances && user.tutor_finances.length > 0) {
        /*
        * tutorFinancesMap
        * currency -> {
        *  type -> item
        * }
        * */
        Array.from(user.tutor_finances).forEach((item: any) => {
          if (!tutorFinancesMap[item.currency]) {
            tutorFinancesMap[item.currency] = {}
          }
          tutorFinancesMap[item.currency][item.type] = item
        })
      }

      user.tutorFinancesMap = tutorFinancesMap
    }

    state.user = user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
