<template>
  <span v-bind="$attrs" :class="{ 'text-gray-500': !colorInherit }"
    >（当地院校时间）</span
  >
</template>

<script>
// Usage: <LabelTimeLocal />
export default {
  props: {
    colorInherit: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
