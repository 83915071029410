import { showToast } from 'vant'

export const useCourseConfirmTips = (route: any) => {
  return new Promise((resolve: any) => {
    const regExpRoutePath = /((\/user\/courses\/1v1\/(\d+))|(\/tutor\/1v1\/courses\/(\d+)))/g
    const needConfirm = route.query.need_confirm === 'true'
    if (route.path.match(regExpRoutePath) && needConfirm) {
      showToast({
        message: '请在手机端操作！',
        duration: 10 * 1000,
        overlay: true,
        onClose () {
          resolve()
        }
      })
    } else {
      resolve()
    }
  })
}
