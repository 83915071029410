import * as Vue from 'vue'
const routes = [
  {
    name: 'study.center',
    path: '/user/courses',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/Index.vue'))
  },
  {
    name: 'study.center.1v1.detail',
    path: '/user/courses/1v1/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/1v1/Detail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.detail',
    path: '/user/courses/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/LearningDetails.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.player',
    path: '/study-center/player/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/PlayerVideo.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'video-player',
    path: '/video-player/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/Video.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.subject',
    path: '/study-center/subject/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/SubjectDetail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.chapter',
    path: '/study-center/chapter/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/ChapterDetail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.recording',
    path: '/study-center/recording',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/VideoRecording.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.courseware',
    path: '/study-center/courseware',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/Courseware.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.topic',
    path: '/study-center/topic/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/Topic.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.evaluation',
    path: '/study-center/evaluation',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/CourseEvaluation.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.invalid',
    path: '/study-center/invalid',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/InvalidCourse.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.pending',
    path: '/study-center/pending',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/PendingCourse.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.evaluate',
    path: '/study-center/evaluate',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/evaluate/list.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'study.center.evaluate.detail',
    path: '/study-center/evaluate/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/evaluate/detail.vue')),
    meta: {
      // auth: true
    }
  },
  {
    name: 'study.center.evaluate.feedback',
    path: '/study-center/evaluate/:id/feedback',
    component: Vue.defineAsyncComponent(() => import('@/pages/study-center/evaluate/feedback.vue')),
    meta: {
      auth: true
    }
  }
]

export default routes
