import * as Vue from 'vue'
const routes = [
  {
    name: 'courses.index',
    path: '/courses',
    component: Vue.defineAsyncComponent(() => import('@/pages/course/List.vue'))
  },
  {
    name: 'courses.show',
    path: '/courses/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/course/Detail.vue'))
  },
  {
    name: 'ware.preview',
    path: '/preview/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/course/Preview.vue')),
    meta: {
      auth: true
    }
  }
]

export default routes
