import * as Vue from 'vue'
export default [
  {
    name: 'activity.teacher',
    path: '/activity/teacher',
    component: Vue.defineAsyncComponent(() => import('@/pages/activity/Teacher.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'activity.teacher23',
    path: '/activity/teacher23',
    component: Vue.defineAsyncComponent(() => import('@/pages/activity/Teacher23.vue')),
  }
]
