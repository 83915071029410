import * as Vue from 'vue'
const routes = [
  {
    name: 'home',
    path: '/',
    component: Vue.defineAsyncComponent(() => import('@/pages/home/Index.vue'))
  }
]

export default routes
