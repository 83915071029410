import * as Vue from 'vue'
const routes = [
  {
    name: 'user.form.bind-phone',
    path: '/user/bind-phone',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/form/BindPhone.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.form.basic',
    path: '/user/form/basic',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/form/Basic.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.form.degree',
    path: '/user/form/degree',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/form/Degree.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.index',
    path: '/user',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Index.vue'))
  },
  {
    name: 'user.show',
    path: '/user/information',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Information.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.coupons',
    path: '/user/coupons',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Coupon.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.wallet',
    path: '/user/wallet',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Wallet.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.income',
    path: '/user/income',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Income.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.financeWay',
    path: '/user/finance-way',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/FinanceWay.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.financeCountry',
    path: '/user/finance-country',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/FinanceCountry.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.finance',
    path: '/user/finance',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Finance.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.about',
    path: '/user/about',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/About.vue'))
  },
  {
    name: 'user.orders',
    path: '/user/orders',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/order/List.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.orders.show',
    path: '/user/orders/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/order/Detail.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.salary.wechat',
    path: '/user/salary/wechat',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/salary/Wechat.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.salary.alipay',
    path: '/user/salary/alipay',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/salary/Alipay.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.salary.paypal',
    path: '/user/salary/paypal',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/salary/Paypal.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.salary.bank',
    path: '/user/salary/bank',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/salary/NewBank.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.verify.id-card',
    path: '/user/verify/id-card',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/VerifyIdCard.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.tutor-daily',
    path: '/tutor-daily',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/TutorDaily.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.vip',
    path: '/user/vip',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Vip.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.points',
    path: '/user/points',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/Points.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.account-setting',
    path: '/user/account-setting/index',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/account-setting/index.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.account-setting.change-phone',
    path: '/user/account-setting/change-phone',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/account-setting/ChangePhone.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.account-setting.pay-password',
    path: '/user/account-setting/pay-password',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/account-setting/PayPassword.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.account-setting.phone',
    path: '/user/account-setting/phone',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/account-setting/Phone.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.account-setting.login-password',
    path: '/user/account-setting/login-password',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/account-setting/LoginPassword.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.account-setting.change-wechat',
    path: '/user/account-setting/change-wechat',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/account-setting/ChangeWechat.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'feedback',
    path: '/feedback',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/feedback/Index.vue'))
  },
  {
    name: 'feedback.course',
    path: '/feedback/course',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/feedback/Course.vue'))
  },
  {
    name: 'feedback.study',
    path: '/feedback/study',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/feedback/Study.vue'))
  },
  {
    name: 'feedback.function',
    path: '/feedback/function',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/feedback/Function.vue')
    )
  },
  {
    name: 'feedback.function',
    path: '/feedback/function',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/feedback/Function.vue')
    )
  },
  {
    name: 'help',
    path: '/help',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/help'))
  },
  {
    name: 'help.detail',
    path: '/help/detail',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/help/detail.vue'))
  },
  {
    name: 'give.away',
    path: '/give-away',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/GiveAway.vue'))
  },
  {
    name: 'form.university',
    path: '/user/form/university',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/user/form/UniversityApply.vue')
    ),
    meta: {
      auth: true
    }
  },
  {
    name: 'user.study.report',
    path: '/user/report-study',
    component: Vue.defineAsyncComponent(() => import('@/pages/user/report-study.vue')),
    meta: {
      auth: true
    }
  }
]

export default routes
