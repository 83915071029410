import type { Plugin } from 'vue'
import * as Sentry from '@sentry/vue'
import router from '@/router'

export const PluginSentry: Plugin = {
  install (app) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserProfilingIntegration(),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration()
      ],

      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions

      tracePropagationTargets: ['https://mobile.hdedu.ai'],

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      // Set profilesSampleRate to 1.0 to profile every transaction.
      profilesSampleRate: 1.0
    })
  }
}
