import localforage from 'localforage'

const KEY_STORE_SUPPORT_PC = 'KEY_STORE_SUPPORT_PC'

export const resolveStorageModePC = () => {
  const store = async () => {
    const val = await localforage.getItem(KEY_STORE_SUPPORT_PC)
    if (!val) {
      await localforage.setItem(KEY_STORE_SUPPORT_PC, 'true')
    }
    // if (!/[^\d]/g.exec(val as any)) {
    //   await localforage.setItem(KEY_STORE_SUPPORT_PC, Number(val) + 1)
    // }
  }

  const clearForce = async () => {
    await localforage.removeItem(KEY_STORE_SUPPORT_PC)
  }

  const clear = async () => {
    const val = await localforage.getItem(KEY_STORE_SUPPORT_PC)
    if (val) {
      clearForce()
    }
  }

  const right = async () => {
    const val = await localforage.getItem(KEY_STORE_SUPPORT_PC)
    return val === 'true'
  }

  return {
    store,
    clear,
    clearForce,
    right
  }
}

const isMobile = () => /(android|iphone|symbianos|windows phone|ipod)/i.test(navigator.userAgent.toLowerCase())

export const useModePC = async () => {
  const mapSearch = searchLocationMap(location.search)

  return !isMobile() && (
    mapSearch.get('support-pc') === 'true' || await resolveStorageModePC().right()
  )
}

export const searchLocationMap = (search: string) => {
  const s = new URLSearchParams(search)
  const arr = Array.from<[string, string]>(s as any)
  return new Map(arr)
}
