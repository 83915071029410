import * as Vue from 'vue'
export default [
  {
    name: 'group.show',
    path: '/group/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/group/Show.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'group.poster.show',
    path: '/group/:id/poster',
    component: Vue.defineAsyncComponent(() => import('@/pages/group/Poster.vue')),
    meta: {
      auth: true
    }
  }
]
