<template>
  <van-dialog
    v-model:show="showDialog"
    class="tutor-agreement-cnt"
    :show-confirm-button="false"
    :close-on-click-overlay="false"
    style="border-radius: 15px"
  >
    <div class="title">
      导师行为准则
    </div>
    <agreement @starts-countdown="onStartsCountdown" />
    <div class="bottom">
      <div
        :class="['btn', { 'disabled-btn': time > 0 }]"
        @click.stop="handleAgree"
      >
        {{ btnText }}
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer'
import Agreement from './components/Agreement.vue'
export default {
  name: 'TutorAgreement',
  components: {
    Agreement
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showDialog: false,
      start: false,
      time: 10,
      timer: null
    }
  },
  computed: {
    btnText () {
      return this.time > 0
        ? `请完整阅读上述准则${this.start ? ` ${this.time}秒` : ''}`
        : '我已阅读，并同意上述准则'
    }
  },
  watch: {
    show (value) {
      this.showDialog = value
    },
    // showDialog(val) {
    //   if (this.show !== val) {
    //     this.$emit('error-disappear')
    //     this.showDialog = true
    //   }
    // },
    time (val) {
      if (val <= 0) {
        clearInterval(this.timer)
      }
    }
  },
  mounted () {
    this.showDialog = this.show
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
  methods: {
    handleAgree () {
      if (this.time <= 0) {
        $emit(this, 'agree')
      } else {
        this.$toast('请下拉阅读全文后再进行下一步操作！')
      }
    },

    onStartsCountdown () {
      if (!this.timer) {
        this.start = true
        this.timer = setInterval(() => {
          this.time -= 1
        }, 1000)
      }
    }
  },
  emits: ['agree']
}
</script>

<style lang="scss" scoped>
.tutor-agreement-cnt {
  .van-dialog__header {
    display: none;
  }

  .title {
    padding: 25px 0 15px;

    font-size: 18px;
    font-weight: 500;
    color: #faa014;
    line-height: 25px;
    text-align: center;
  }

  .bottom {
    padding: 15px 20px;

    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.05);
  }

  .btn {
    padding: 9px 0;

    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    text-align: center;

    background: #ffd811;
    border-radius: 19px;
  }

  .disabled-btn {
    background: #e3e3e3;
  }
}
</style>
