<template>
  <van-dialog
    class="tutor-signature-cnt"
    v-model:show="showDialog"
    :show-confirm-button="false"
    :close-on-click-overlay="false"
    style="border-radius: 15px"
  >
    <div class="position-relative">
      <div class="title">为保障你的权益请输入以下声明</div>
      <div class="tip">本人已阅读导师行为准则，愿意承担相关后果</div>
      <van-field
        v-model="signature"
        type="textarea"
        placeholder="请输入上述声明内容"
        maxlength="20"
        class="input"
      />
      <div
        class="copy-btn"
        data-clipboard-text="本人已阅读导师行为准则，愿意承担相关后果"
        @click.stop="handleAutoInput"
      >
        一键输入
      </div>
      <div class="btn-list">
        <div class="btn pre-btn" @click.stop="$emit('go-back')">上一步</div>
        <div class="btn confirm-btn" @click.stop="handleSubmit">确认提交</div>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer'
import ClipboardJS from 'clipboard'

export default {
  name: 'TutorSignature',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showDialog: false,
      signature: '',
      clipboard: null
    }
  },
  watch: {
    show (value) {
      this.showDialog = value
    }
  },
  mounted () {
    this.showDialog = this.show
    this.$nextTick(() => {
      this.clipboard = new ClipboardJS('.copy-btn')
    })
  },
  methods: {
    handleAutoInput () {
      this.signature = '本人已阅读导师行为准则，愿意承担相关后果'
      this.clipboard.on('success', (e) => {
        this.clipboard.destroy()
        this.clipboard = new ClipboardJS(this.copyBtn)
        e.clearSelection()
      })
    },

    handleSubmit () {
      if (!this.signature) {
        this.$toast('请输入声明内容')
      } else if (
        this.signature !== '本人已阅读导师行为准则，愿意承担相关后果'
      ) {
        this.$toast('内容有误，可点击“一键输入”快速更正')
      } else {
        $emit(this, 'submit')
      }
    }
  },
  emits: ['go-back', 'submit']
}
</script>

<style lang="scss" scoped>
.van-dialog__header {
  display: none;
}
.tutor-signature-cnt {
  padding: 0 20px;
  font-size: 14px;
  line-height: 20px;
  .title {
    padding: 25px 0 15px;

    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    text-align: center;
  }

  .tip {
    margin-bottom: 10px;

    color: #faa014;
  }

  .copy-btn {
    position: absolute;
    bottom: 94px;
    left: 15px;

    padding: 4px 12px;

    font-size: 12px;
    color: #faa014;
    line-height: 16px;

    border: 1px solid rgba(250, 160, 20, 0.7);
    background: rgba(250, 160, 20, 0.1);
    border-radius: 16px;
  }

  .input {
    align-items: initial;

    height: 110px;
    padding: 15px;

    border: 1px solid #dddddd;
    border-radius: 6px;
  }
}
.btn-list {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
  .btn {
    padding: 10px 30px;

    font-weight: 500;
    color: #333333;

    border-radius: 20px;
  }

  .pre-btn {
    border: 1px solid #333333;
  }

  .confirm-btn {
    margin-left: 20px;

    background: #ffd811;
  }
}
</style>
