import { getWechatQrcodeStatus } from '@/api'
import localforage from 'localforage'
import { showNotify } from 'vant'
import store from '@/store'
import router from '@/router'

export const isArray = (obj: any) => {
  return obj instanceof Array
}

export const isWechatBrowser = () => {
  const ua = navigator.userAgent.toLowerCase()
  return /micromessenger/.test(ua)
}

export const computeTime = (time: number) => {
  const day = Math.floor(time / (24 * 60 * 60))
  const hour = Math.floor(time / (60 * 60) % 24)
  const minute = Math.floor(time / 60 % 60)
  const second = Math.floor(time % 60)

  return {
    day: day < 10 ? '0' + day : day,
    hour: hour < 10 ? '0' + hour : hour,
    minute: minute < 10 ? '0' + minute : minute,
    second: second < 10 ? '0' + second : second
  }
}

export const getError = (response: any) => {
  if (!response) return false

  if (typeof response.data === 'string' || (!response.data.errors && typeof response.data.message === 'string')) {
    showNotify({ type: 'danger', message: response.data === 'string' ? response.data : response.data.message })
  } else {
    const data = response.data.errors
    let content = ''

    Object.keys(data).map((key: string) => {
      const value = data[key]

      content = value[0]

      return key
    })

    showNotify({ type: 'danger', message: content })
  }
}

export const scrollSmoothTo = (position: number) => {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (callback) => {
      return setTimeout(callback, 10)
    }
  }

  let scrollTop = document.body.scrollTop || document.documentElement.scrollTop

  const step = () => {
    const distance = position - scrollTop

    scrollTop = scrollTop + distance / 5

    if (Math.abs(distance) < 1) {
      window.scrollTo(0, position)
    } else {
      window.scrollTo(0, scrollTop)
      requestAnimationFrame(step)
    }
  }

  step()
}

export const formatDate = (start: string, end: string) => {
  return start.slice(5, start.length - 3) + ' - ' + end.slice(10, end.length - 3)
}

/**
 * 生成随机字符串
 * @param {*} len
 * @returns
 */
export const randomString = (len: number) => {
  len = len || 32
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/**
 * 获取静态资源链接
 *
 * @param path
 * @returns
 */
export const requireUrl = (path: string, isMobile: boolean = true) => {
  if (isMobile) {
    if (path.indexOf('@assets') > -1) {
      path = path.replace('@assets', '')
    }

    return import.meta.env.VITE_APP_STATIC_OSS_DOMAIN + '/static/mobile' + path
  } else {
    return import.meta.env.VITE_APP_STATIC_OSS_DOMAIN + path
  }
}

/**
 * 检测扫码登录状态
 */
export const checkQrcodeStatus = async (flag = null, callback = () => {}) => {
  const flagTemp = await localforage.getItem('auth.flag')

  if (!flag && !flagTemp) return false

  const { data } = await getWechatQrcodeStatus({
    flag: flag || flagTemp
  })

  if (data.scan) {
    const token = data.token

    localforage.removeItem('auth.flag')
    localforage.setItem('auth.token', token)

    store.dispatch('auth/setToken', token)
    store.dispatch('auth/checkUserToken')
    store.dispatch('auth/loadUser').then(() => {
      if (callback) {
        callback()
      } else {
        router.push('/user')
      }
    })
  } else {
    setTimeout(() => {
      checkQrcodeStatus(null, callback)
    }, 1000)
  }
}
