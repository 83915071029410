<template>
  <van-dialog
    class="pb-2 w-85"
    :class="titleClass"
    v-model:show="showDialog"
    title="账号封禁"
    :show-confirm-button="false"
    :close-on-click-overlay="false"
    style="border-radius: 15px"
    @close="onClose"
  >
  <div class="icon" style="width: 25px;height: 25px;top:0;">
      <img :src="icon" style="width: 25px;height: 25px;" />
    </div>
    <div class="px-2 pt-2 pb-2" :class="colorClass">
      <p
        v-for="text in tips.top"
        class="mt-0 text-bold"
        :key="text"
        style="text-indent: 2rem"
      >
        {{ text }}
      </p>
      <p class="my-1">封禁ID：{{ userId }}<br /></p>
      <p class="my-1 text-bold">
        封禁处置生效时间：{{ userBan.time }}<br />
        <span v-for="text in tips.center" :key="text">
          {{ text }}
        </span>
      </p>
      <p
        v-for="text in tips.bottom"
        class="mt-0"
        :key="text"
        style="text-indent: 2rem"
      >
        {{ text }}
      </p>
      <div class="w-50 mx-auto mt-2">
        <img class="w-100" :src="QRCode" alt="" />
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer'
import { mapGetters } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isAuto: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: Object,
      required: true,
    },
    type: {
      type: Number,
      default: 0
    }
  },
  emits: ['close'],
  data: () => ({
    showDialog: false,
  }),
  computed: {
    ...mapGetters('auth', {
      userBan: 'userBan',
      user: 'currentUser',
    }),
    icon () {
      return this.requireUrl(`/svg/icon${this.isAuto ? '-auto' : ''}-ban.svg`)
    },
    QRCode () {
      return this.requireUrl(`/images/ban-qrcode${this.isAuto ? '1' : (this.type === 8 ? '8' : '')}.png`)
    },
    titleClass () {
      return this.isAuto ? 'orange-title' : 'danger-title'
    },
    colorClass () {
      return this.isAuto ? 'text-orange' : 'text-danger'
    },
    userId () {
      const id = this.user ? String(this.user.id) : ''

      return id.length < 8 ? id.padStart(8, '0') : id
    },
  },
  watch: {
    show (value) {
      this.showDialog = value
    },
  },
  mounted () {
    this.showDialog = this.show
  },
  methods: {
    onClose () {
      $emit(this, 'close')
    },
  },

}
</script>

<style lang="scss" scoped>
.icon {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}
.van-dialog {
  overflow: visible !important;
}
.text-danger {
  color: #ff2d2d;
}
.text-orange {
  color: #ffa014;
}
.van-dialog__header {
  padding-top: 50px;
  font-size: 18px;
}
.danger-title .van-dialog__header {
  color: #ff2d2d;
}
.orange-title .van-dialog__header {
  color: #ffa014;
}
</style>
