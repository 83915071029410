import * as Vue from 'vue'
const routes = [
  {
    name: 'auth.login.phone',
    path: '/auth/login-width-phone',
    component: Vue.defineAsyncComponent(() => import('@/pages/auth/LoginWithPhone.vue'))
  },
  {
    name: 'auth.login',
    path: '/auth/login',
    component: Vue.defineAsyncComponent(() => import('@/pages/auth/Login.vue'))
  },
  {
    name: 'auth.browser.login',
    path: '/auth/browser',
    component: Vue.defineAsyncComponent(() => import('@/pages/auth/BrowserLogin.vue'))
  },
  {
    name: 'auth.login.password',
    path: '/auth/login/password',
    component: Vue.defineAsyncComponent(() => import('@/pages/auth/LoginByPassword.vue'))
  },
  {
    name: 'auth.register',
    path: '/auth/register',
    component: Vue.defineAsyncComponent(() => import('@/pages/auth/Register.vue'))
  },
  {
    name: 'auth.password.forget',
    path: '/auth/password/forget',
    component: Vue.defineAsyncComponent(() => import('@/pages/auth/ForgetPassword.vue'))
  }
]

export default routes
