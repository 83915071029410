import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import $axios from 'axios'
import { CodeTerminal, CodeBusiness } from './types'
import { config } from 'localforage'

// GET /app/v1/sign
// GET /app/v1/doLogin
// GET /app/v1/logout 暂可不用
// POST /app/v1/message
// POST /app/v1/globalPageMessage

const baseURL = import.meta.env.VITE_APP_ETL_API_URL

const KEY_STORAGE_AES = 'KEY_STORAGE_AES'
const NAME_TOKEN_ETL = 'NAME_TOKEN_ETL'
const VAL_TOKEN_ETL = 'VAL_TOKEN_ETL'
const TIMEOUT_TOKEN_ETL = 'TIMEOUT_TOKEN_ETL'

const etl: any = {}

const getReqHeaders = () => {
  const key: any = localStorage.getItem(NAME_TOKEN_ETL)
  const val = localStorage.getItem(VAL_TOKEN_ETL)
  const canSet = key && val
  return {
    headers: {
      [key]: val
    },
    canSet
  }
}

const getKeyAES = () => {
  return {
    keyAES: localStorage.getItem(KEY_STORAGE_AES) // AES 加密密钥，由 etl.sign 接口获取
  }
}

const aesEncrypt = (config: any) => {
  const keyAES = config.key || getKeyAES().keyAES || ''
  const [data, key] = [config.data, keyAES].map(CryptoJS.enc.Utf8.parse)
  const encrypted = CryptoJS.AES.encrypt(data, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  return encrypted.ciphertext.toString()
}

let timerInit: any
const handleResonse = (res: any) => {
  const { data } = res
  // 过期 或 未登录
  if (data.code === 403) {
    localStorage.removeItem(KEY_STORAGE_AES)
    if (!timerInit) {
      timerInit = setTimeout(() => {
        etl.init()
      }, 5000)
    }
  } else if (timerInit) {
    clearTimeout(timerInit)
    timerInit = undefined
  }
  return res
}

// 获取 AES 加密参数
etl.sign = () => {
  const { headers, canSet } = getReqHeaders()
  if (canSet) {
    return $axios
      .get('/etl/app/v1/sign', {
        baseURL,
        headers
      })
      .then(handleResonse)
  } else {
    return Promise.resolve()
  }
}

// 登录获取 token 相关参数
etl.login = () => {
  return $axios
    .get('/etl/app/v1/doLogin', {
      baseURL
    })
    .then(handleResonse)
}

const setToken = (key: string, value: string, expires: number) => {
  const token = `${key}=${value}`

  localStorage.setItem(NAME_TOKEN_ETL, key)
  localStorage.setItem(VAL_TOKEN_ETL, value)
  localStorage.setItem(TIMEOUT_TOKEN_ETL, String(expires))

  return token
}

// 初始化 ETL
etl.init = async () => {
  const resLogin = await etl.login()
  const { tokenName, tokenValue, tokenTimeout } = (resLogin.data || {}).data || {}

  setToken(tokenName, tokenValue, Math.round(tokenTimeout / 86400))

  const curKeyETL = localStorage.getItem(KEY_STORAGE_AES)
  if (!curKeyETL) {
    const resSign = await etl.sign()
    if (resSign && resSign.data.code === 200) {
      const { key } = resSign.data.data
      if (key) {
        localStorage.setItem(KEY_STORAGE_AES, key)
      }
    }
  }
}

// exp usage: postMessage
// const dataFake = {
//   code: CodeEventEtl.START_WATCH_VIDEO_ONLINE,
//   terminalType: 'web',
//   data: {
//     distinctId: 'userId',
//     lessonId: 'id',
//     time: '2022-08-08 17:50:00'
//   }
// }
// etl.postMessage(dataFake)

const getTime = () => dayjs().format('YYYY-MM-DD HH:mm:ss')

// 数据上报
etl.postMessage = (configs: any) => {
  try {
    const {
      code,
      terminalType = CodeTerminal.MOBILE,
      businessType = CodeBusiness.MOBILE,
      data,
      globalPost = false
    } = configs
    const useData = {
      code,
      terminalType: data.terminalType || terminalType,
      businessType: data.businessType || businessType,
      data: {
        time: getTime(),
        // @ts-ignore
        os: (navigator.userAgentData || {}).platform || navigator.platform || '',
        ...data
      }
    }

    const { headers, canSet } = getReqHeaders()
    const { keyAES } = getKeyAES()

    if (canSet && keyAES) {
      return $axios
        .post(
          globalPost ? '/etl/app/v1/globalPageMessage' : '/etl/app/v1/message',
          {
            encryptionStr: aesEncrypt({
              data: JSON.stringify(useData),
              key: keyAES
            })
          },
          {
            baseURL,
            headers
          }
        )
        .then(handleResonse)
        .catch(() => {})
    } else {
      return Promise.resolve()
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export { etl }
