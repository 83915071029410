import * as Vue from 'vue'
const routes = [
  {
    name: 'static_page.success',
    path: '/static_page/success',
    component: Vue.defineAsyncComponent(() => import('@/pages/static-page/Success.vue'))
  },
  {
    name: 'static_page.fail',
    path: '/static_page/fail',
    component: Vue.defineAsyncComponent(() => import('@/pages/static-page/Fail.vue'))
  },
  {
    name: 'static_page.result',
    path: '/static_page/result',
    component: Vue.defineAsyncComponent(() => import('@/pages/static-page/Result.vue'))
  }
]

export default routes
