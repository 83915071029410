import { isWechatBrowser } from '@utils/helpers'

export default {
  data: () => ({
    share: {
      title: 'HD Education',
      desc: '快加入我们吧！',
      link: `https://${window.location.host}`,
      image: `https://${window.location.host}/logo.jpg`
    }
  }),

  async mounted () {
    if (isWechatBrowser() && !this.share.isSpecial) {
      await this.initSdkConfig()

      // eslint-disable-next-line no-undef
      wx.ready(() => {
        this.onSetMenu()
      })
    }
  },

  methods: {
    onSetMenu () {
      // eslint-disable-next-line no-undef
      wx.onMenuShareTimeline({
        title: this.share.title,
        link: this.share.link,
        imgUrl: this.share.image
      })
      // eslint-disable-next-line no-undef
      wx.onMenuShareAppMessage({
        title: this.share.title,
        desc: this.share.desc,
        link: this.share.link,
        imgUrl: this.share.image
      })
      // eslint-disable-next-line no-undef
      wx.onMenuShareQQ({
        title: this.share.title,
        desc: this.share.desc,
        link: this.share.link,
        imgUrl: this.share.image
      })
      // eslint-disable-next-line no-undef
      wx.onMenuShareWeibo({
        title: this.share.title,
        desc: this.share.desc,
        link: this.share.link,
        imgUrl: this.share.image
      })
      // eslint-disable-next-line no-undef
      wx.onMenuShareQZone({
        title: this.share.title,
        desc: this.share.desc,
        link: this.share.link,
        imgUrl: this.share.image
      })
    },

    initSdkConfig () {
      return this.$http.get(`jssdk-config?url=${encodeURIComponent(window.location.href.split('#')[0])}`)
        .then(({ data }) => {
          // eslint-disable-next-line no-undef
          wx.config(Object.assign(data, {
            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone']
          }))
        })
    }
  }
}
