/* eslint-disable */
import KatexAutoRender from 'katex/contrib/auto-render'
// const KatexAutoRender = require('./auto-render')
export default function (el:any, binding:any) {
    let options = {
        delimiters: [
        {left: "$$", right: "$$", display: true},
        {left: "\\$", right: "\\$", display: false},
        {left: "\\(", right: "\\)", display: false},
        // {left: "\(", right: "\)", display: false},
        {left: "\\begin{equation}", right: "\\end{equation}", display: true},
        {left: "\\begin{align}", right: "\\end{align}", display: true},
        {left: "\\begin{alignat}", right: "\\end{alignat}", display: true},
        {left: "\\begin{gather}", right: "\\end{gather}", display: true},
        {left: "\\begin{CD}", right: "\\end{CD}", display: true},
        {left: "\\[", right: "\\]", display: true},
        // {left: "\[", right: "\]", display: false},
        ]
    }
    if (binding.value) {
        options = binding.value
    }
    // @ts-ignore
    // console.log('KatexAutoRender---', el)
    el.style.position = 'relative'
    KatexAutoRender(el, options)
}
