<template>
  <div class="agreement-cnt text-14">
    <RulesContent :type="8" hide-header />
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer'
import RulesContent from '@components/rules/RulesContent.vue'

export default {
  name: 'Agreement',
  components: {
    RulesContent
  },
  mounted () {
    this.$nextTick(() => {
      const dom = document.getElementsByClassName('agreement-cnt')[0]
      dom.addEventListener('scroll', () => {
        const { scrollHeight, clientHeight, scrollTop } = dom
        const bottomDistance =
          parseInt(scrollHeight - clientHeight) - parseInt(scrollTop)
        if (bottomDistance < 20) {
          $emit(this, 'starts-countdown')
        }
      })
    })
  },
  emits: ['starts-countdown']
}
</script>

<style lang="scss" scoped>
.agreement-cnt {
  max-height: 407px;
  margin: 0 10px 0 20px;
  padding-right: 4px;
  overflow-y: scroll;
  .text-highlight {
    font-weight: bold;
    text-decoration: underline;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background: #eeeeee;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffd811;
    border-radius: 3px;
  }
}
p,
.content {
  font-size: 14px;
}
.text-indent {
  text-indent: 1em;
}
.text-indent-2 {
  text-indent: 2em;
}
.text-xl {
  font-size: 1rem;
}
</style>
