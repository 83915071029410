import { createStore } from 'vuex'
import auth from './modules/auth'

const debug = import.meta.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    auth
  },
  strict: debug
})
