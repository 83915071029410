<template>
  <van-dialog
    class="pb-2 w-75"
    v-model:show="showDialog"
    :show-confirm-button="false"
    :close-on-click-overlay="false"
    style="border-radius: 15px"
    @close="onClose"
  >
    <div class="px-2 pt-2 pb-2 text-center">
      {{ text }}
    </div>
    <div class="text-center text-13 mb-0">
      <button
        v-if="showCancelBtn"
        class="px-3 py-1 mr-1 bg-white rounded-20px border-dark"
        @click="onClose"
      >
        {{ cancelText }}
      </button>
      <button
        class="px-3 py-1 bg-yellow rounded-20px border-yellow"
        @click="onSubmit"
      >
        {{ buttonText }}
      </button>
    </div>
  </van-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '我知道了',
    },
    text: {
      type: String,
      required: true,
    },
    showCancelBtn: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '取消',
    },
  },
  data: () => ({
    showDialog: false,
  }),
  watch: {
    show(value) {
      this.showDialog = value
    },
  },
  mounted() {
    this.showDialog = this.show
  },
  methods: {
    onSubmit() {
      $emit(this, 'submit')
    },

    onClose() {
      $emit(this, 'close')
    },
  },
  emits: ['submit', 'close'],
}
</script>
