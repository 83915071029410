import axios from 'axios'
import interceptors from './interceptors'

const http = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 30000
})

interceptors(http)

export function setToken (token: string) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`
  http.defaults.headers.common.Accept = 'application/json'
}

export default http
