import * as Vue from 'vue'
const routes = [
  {
    name: 'hilink.index',
    path: '/hilink/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/hilink/Index.vue')),
    meta: {
      auth: true
    }
  }
]

export default routes
