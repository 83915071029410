import { randomString } from '@/utils/helpers'
import { getRSAPubKey } from '@/api/common'
import { showToast } from 'vant'
import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'

const Encrypt = async (data: any) => {
  const aesKey = randomString(16)
  let pubKey = ''

  pubKey = await Encrypt.getPublicKey()

  const sendData = Encrypt.aesEncrypt(JSON.stringify(data), aesKey)
  const encrypted = Encrypt.encryptAesKeyByPubKey(aesKey, pubKey)

  return {
    sendData,
    encrypted
  }
}

/**
 * 获取 RSA 公钥
 */
Encrypt.getPublicKey = async () => {
  try {
    const { data } = await getRSAPubKey()

    return data.data
  } catch (e: any) {
    showToast(e)
  }
}

/**
 * AES 加密方法
 * @param text
 * @param key
 * @returns
 */
Encrypt.aesEncrypt = (text: any, aesKey: string) => {
  const key = CryptoJS.enc.Utf8.parse(aesKey)

  const encryptText = CryptoJS.enc.Utf8.parse(text)

  return CryptoJS.AES.encrypt(encryptText, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()
}

/**
 * AES 解密方法
 * @param encryptedText
 * @param key
 */
Encrypt.aesDecrypt = (encryptedText: string, aesKey: string) => {
  const key = CryptoJS.enc.Utf8.parse(aesKey)

  return CryptoJS.AES.decrypt(encryptedText, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()
}

/**
 * 对 AES key 进行非对称加密
 * @param aesKey
 * @param pubKey
 * @returns
 */
Encrypt.encryptAesKeyByPubKey = (aesKey: string, pubKey: string) => {
  const encrypt = new JSEncrypt()

  encrypt.setPublicKey(pubKey)

  return encrypt.encrypt(aesKey)
}

export default Encrypt
