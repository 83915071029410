import http from '@/utils/http'

// Course
export const getCoursesByUser = (params) => http.get('my-courses', { params })

// Payment
export const getPayments = (params) => http.get('payments', { params })

// Upgrade
export const getUserUpgradeNotice = () => http.get('member-upgrade')

export const markUserUpgradeNotice = () => http.post('member-upgrade-read')

// Points
export const getPoints = (params) => http.get('user-point-records', { params })

// User
export const getWechatQrcodSource = () => http.get('wechat/qrcode/source')

export const patchUser = (params) => http.patch('user', params)

export const putIdentity = (params) => http.put('user/identity', params)

export const patchPrefectInfo = (params) => http.patch('users/perfect-information', params)

export const patchPrefectExtInfo = (params) => http.patch('users/perfect-ext-information', params)

export const patchImproveInfo = (params) => http.patch('users/improve-personalized-information', params)

// Account
export const mergeAccount = (params) => http.post('users/merge-account', params)

export const unbindWechat = () => http.patch('user/unbind-wechat')

export const changePhone = (params) => http.patch('user-phone', params)

export const postPhoneStatus = (params) => http.post('phone-status', params)

// Level
export const getUserLevel = () => http.get('member-center')

// Study Center
export const markLastStudy = (params) => http.post('student-last-study', params)

export const getStudentSemester = () => http.get('student-semesters')

export const setCourseTop = (params) => http.post('student-class-top', params)

export const getStudentCourses = (params) => http.get('student-classes', { params })

export const getStudentCoursesBySemester = (params) => http.get('student-courses', { params })

export const getStudentUniversities = () => http.get('student-universities')

export const changeStudentUniversity = (params) => http.post('student-university', params)

// 1v1 Study Courses
export const get1v1StudyCourses = (params) => http.get('v3/user-product-classes', { params })

export const get1v1StudyCourseById = (id, params) => http.get(`v3/user-product-classes/${id}`, { params })

export const top1v1UserCourse = (id) => http.get(`v3/user-classes-top/${id}`)

// Feedback
export const getFeedbackTypes = () => http.get('question-types')

export const postFeedback = (params) => http.post('feed-back', params)

// 微信合并账号
export const postWeChatMerge = (params) => http.post('users/merge-wechat-account', params)
export const getCourseFeedbackTypes = () => http.get('feedback/class/const')

export const getStudyFeedbackTypes = () => http.get('feedback/study/const')

export const postCourseFeedback = (params) => http.post('feedback/class/create', params)
// 学术诚信专项返回提交
export const postCourseFeedbackXszx = (params) => http.post('feedback/faith/create', params)

export const postStudyFeedback = (params, type) => type ? http.post('feedback/study/create2', params) : http.post('feedback/study/create', params)

// 获取订单转赠订单
export const getTransferOrder = (id) => http.get('get-transfer-order', { params: { trade_no: id } })
// 转赠订单确认
export const postSubmitTransferOrder = (params) => http.post('submit-transfer-order', params)

// 检测用户院校审批信息
export const checkUserUniversityApproval = () => http.post('user/university/check')

// 提交用户切换院校审批
export const postUserUniversityApprovel = (params) => http.post('user/university/change', params)

/**
 *
 * 学生待定课程
 *
 * @param params
 *
 * @returns
 */
export const getUserPendingCourses = (params: any) => {
  return http.get('student-pending-classes', {
    params
  })
}
