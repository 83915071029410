import * as Vue from 'vue'
const routes = [
  {
    name: 'rule.index',
    path: '/rule',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Index.vue'))
  },
  {
    name: 'rule.detail',
    path: '/rules/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Detail.vue'))
  },
  {
    name: 'rule.refund.class',
    path: '/rule/refund',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/refund/Class.vue'))
  },
  {
    name: 'rule.refund.1v1',
    path: '/rule/refund/1v1',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/refund/1v1.vue'))
  },
  {
    name: 'rule.balance',
    path: '/rule/balance',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Balance.vue'))
  },
  {
    name: 'rule.tfn',
    path: '/rule/tfn',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/TFN.vue'))
  },
  {
    name: 'rule.agreement',
    path: '/rule/agreement',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Agreement.vue'))
  },
  {
    name: 'rule.privacy',
    path: '/rule/privacy',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Privacy.vue'))
  },
  {
    name: 'rule.copyright',
    path: '/rule/copyright',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Copyright.vue'))
  },
  {
    name: 'rule.contact',
    path: '/rule/contact',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Contact.vue'))
  },
  {
    name: 'rule.qrcode.service',
    path: '/rule/qrcode/customer-service',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/rule/qrcode/CustomerService.vue')
    )
  },
  {
    name: 'rule.qrcode.hr',
    path: '/rule/qrcode/hr',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/qrcode/Hr.vue'))
  },
  {
    name: 'rule.qrcode.bd',
    path: '/rule/qrcode/bd',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/qrcode/BD.vue'))
  },
  {
    name: 'rule.group',
    path: '/rule/group',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Group.vue'))
  },
  {
    name: 'rule.vip',
    path: '/rule/vip',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/Vip.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'rule.tutor-level',
    path: '/rule/tutor-level',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/TutorLevel.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'rule.refund.class.static',
    path: '/rule/refund_static',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/rule/refund/ClassStatic.vue')
    )
  },
  {
    name: 'rule.refund.1v1.static',
    path: '/rule/refund/1v1_static',
    component: Vue.defineAsyncComponent(() => import('@/pages/rule/refund/1v1Static.vue'))
  },
  {
    name: 'rule.refund.career.static',
    path: '/rule/refund/career_static',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/rule/refund/CareerStatic.vue')
    )
  },
  {
    name: 'rule.refund.recharge.static',
    path: '/rule/refund/recharge_static',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/rule/refund/ValueCardStatic.vue')
    )
  },
  {
    name: 'rule.bantips',
    path: '/rule/bantips',
    component: Vue.defineAsyncComponent(
      () => import('@/pages/rule/BanTips.vue')
    )
  }
]

export default routes
