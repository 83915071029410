// 1 是未开始（去上课）
// 2 是直播中（看直播）
// 3 是看录播（已经结束）
// 4 是已结束（但是还没有录播
// 5 已迟到（看直播）
// 6 超时未开课
export enum StatusLive {
  NOT_START = 1,
  LIVING = 2,
  PLAYBACK = 3,
  END = 4,
  LATE = 5, // 已废弃
  TIMEOUT = 6
}
