const routes = [
  {
    name: 'offer.qrcode',
    path: '/offer/qrcode/:id',
    component: () => import('@/pages/offer/qrcode.vue'),
    meta: {
      auth: true
    }
  }
]

export default routes
