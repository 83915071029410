import store from '@store/index'
import { etl } from './etl'
import { CodeEventEtl } from './types'

export const etlStartRecord = (data: any) => {
  return etl.postMessage({
    code: CodeEventEtl.START_WATCH_VIDEO,
    data
  })
}

export const etlStopRecord = (data: any) => {
  return etl.postMessage({
    code: CodeEventEtl.FINISH_WATCH_VIDEO,
    data
  })
}

export const etlRecordIM = (data: any) => {
  return etl.postMessage({
    code: CodeEventEtl.WRITTEN_COMMUNICATION,
    data
  })
}

export const etlPostLive = (data: any) => {
  return etl.postMessage({
    code: CodeEventEtl.START_WATCH_VIDEO_ONLINE,
    data
  })
}

export const etlStopLive = (data: any) => {
  return etl.postMessage({
    code: CodeEventEtl.FINISH_WATCH_VIDEO_ONLINE,
    data
  })
}

export const etlPageView = (params: any, dataPayload: any = {}) => {
  const { code, globalPost = false, pageId } = params || {}
  const user = (store.state.auth.user || {})

  const data: any = {
    distinctId: user.id || 0
  }

  if (globalPost) {
    data.pageId = pageId

    const university = user.university || {}

    const { businessId } = dataPayload

    // businessId 为 `true` 自动生成唯一随机值
    if (businessId === true) {
      let devideSymbolVal = localStorage.getItem('DevideSymbol')
      if (!devideSymbolVal) {
        devideSymbolVal = Math.random().toString(36).slice(2)
        localStorage.setItem('DevideSymbol', devideSymbolVal)
      }
      dataPayload.businessId = devideSymbolVal
    }

    Object.assign(data, {
      universitiesId: university.id, // 院校 id
      universitiesName: university.name, // 院校名
      universitiesCode: university.short_name, // 院校 code
      universitiesCountry: (university.country || {}).name, // 院校所属国家
      ...dataPayload
    })
  }

  return etl.postMessage({
    code,
    data,
    globalPost
  })
}
