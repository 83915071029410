import http from '@/utils/http'

export const getUser = () => http.get('users/information')

export const getTokenByCode = (code: string, configs: any) => http.get('users/token', Object.assign({ params: { code } }, configs))

export const getTokenByPassword = (params: any, configs: any) => http.post('login', params, configs)

export const getTokenByVerifyCode = (params: any, configs: any) => http.post('quick-login', params, configs)

export const registerUser = (params: any) => http.post('register', params)

export const getSMS = (params: any) => http.post('sms', params)

export const resetPassword = (params: any) => http.post('password/reset', params)

export const getWechatQrcode = () => http.get('wechat/qrcode/create')

export const getWechatQrcodeStatus = (params: any) => http.get('wechat/qrcode/auth/status', {
  params: {
    ...params,
    is_pc: 0
  }
})
