import { createRouter, createWebHistory } from 'vue-router'
import BeforeEach from './beforeEach'
import { afterEach } from './afterEach'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(BeforeEach)
router.afterEach(afterEach)

export default router
