import store from '@store'
import localforage from 'localforage'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { useCourseConfirmTips } from '@utils/course-confirm'
import { resolveStorageModePC, useModePC } from '@/utils/device'
import { showToast } from 'vant'

const beforeEach = async (to: any, from: any, next: any) => {
  const type = store.state.auth.ban.type

  const isMobile = /(android|iphone|symbianos|windows phone|ipod)/i.test(navigator.userAgent.toLowerCase())

  const { host, hostname, pathname, search } = location

  const viewModePC = await useModePC()

  if (viewModePC) {
    await resolveStorageModePC().store()
  }

  if (!isMobile && !viewModePC) {
    await useCourseConfirmTips(to)

    const el = window.location.host.split('.')

    let url = `https://${host}`

    if (['mobile.hdedu.fun', 'mobile.hdedu.ai'].includes(hostname)) {
      url = `https://www.${el[1]}.${el[2]}`
    }

    if (pathname === '/user/orders') {
      url += '/user/courses'
    } else if (pathname.includes('package') && pathname.includes('course')) {
      url += `/activities/${pathname.split('/').filter((i) => i)[1]}`
    } else if (pathname === '/order/recharge') {
      url += '/user/recharge'
    } else {
      url += pathname
    }

    url += search

    window.location.href = url
    return false
  } else if (to.path !== '/' && ![0, 1, 7].includes(type)) {
    next('/')
  } else if (to.query.is_bind_mobile_phone === '0') {
    const query = { ...to.query }
    delete query.is_bind_mobile_phone
    next({
      name: 'user.form.bind-phone',
      query: {
        ...query,
        path: to.path,
        code: to.query.code
      }
    })
  } else if (to.query.code) {
    try {
      await store.dispatch('auth/loginByCode', {
        code: to.query.code,
        path: to.path,
        query: to.query
      })

      next()
    } catch (e) {
      const query = cloneDeep(to.query)

      delete query.code

      next({
        path: to.path,
        query
      })
    }
  } else if (to.meta.auth) {
    try {
      await store.dispatch('auth/checkUserToken', {
        path: to.path,
        query: to.query
      })
      next()
    } catch (error) {
      if (to.query) {
        window.location.href = `${import.meta.env.VITE_APP_MOBILE_URL}auth/login?from=${to.name
            .split('.')
            .join('-')}&${Object.keys(to.query)
            .map((key) => `${key}=${to.query[key]}`)
            .join('&')}&path=${encodeURIComponent(
            to.path +
              '?' +
              Object.keys(to.query)
                .map((key) => `${key}=${to.query[key]}`)
                .join('&')
          )}`
      } else {
        window.location.href = `${import.meta.env.VITE_APP_MOBILE_URL}auth/login?from=${to.name
            .split('.')
            .join('-')}&path=${to.path}`
      }
    }
  } else {
    const token = await localforage.getItem('auth.token')
    if (!isEmpty(token) && to.name && to.name.split('.')[0] === 'auth') {
      next({
        name: 'user.index'
      })
    } else {
      next()
    }
  }
}

export default beforeEach
