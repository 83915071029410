import * as Vue from 'vue'
const routes = [
  {
    name: 'guide.index',
    path: '/guide',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Index.vue'))
  },
  {
    name: 'guide.buy',
    path: '/guide/buy',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Buy.vue'))
  },
  {
    name: 'guide.recharge',
    path: '/guide/recharge',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Recharge.vue'))
  },
  {
    name: 'guide.order',
    path: '/guide/order',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Order.vue'))
  },
  {
    name: 'guide.tutor',
    path: '/guide/tutor',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Tutor.vue'))
  },
  {
    name: 'guide.registered',
    path: '/guide/registered',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Registered.vue'))
  },
  {
    name: 'guide.coupon',
    path: '/guide/coupon',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Coupon.vue'))
  },
  {
    name: 'guide.resource',
    path: '/guide/resource',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Resource.vue'))
  },
  {
    name: 'guide.pay',
    path: '/guide/pay',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Pay.vue'))
  },
  {
    name: 'guide.apply',
    path: '/guide/apply',
    component: Vue.defineAsyncComponent(() => import('@/pages/guide/Apply.vue'))
  }
]

export default routes
