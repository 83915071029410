import * as Vue from 'vue'
const routes = [
  {
    name: 'aic.cn',
    path: '/aic',
    component: Vue.defineAsyncComponent(() => import('@/pages/aic/CN.vue'))
  },
  {
    name: 'aic.en',
    path: '/aic/en',
    component: Vue.defineAsyncComponent(() => import('@/pages/aic/EN.vue'))
  }
]

export default routes
