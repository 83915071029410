import * as Vue from 'vue'
export default [
  {
    name: '1v1.index',
    path: '/1v1',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Index.vue'))
  },
  {
    name: '1v1.ad.index',
    path: '/ad/1v1',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Ad.vue'))
  },
  {
    name: '1v1.thesis',
    path: '/1v1/thesis',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Thesis.vue'))
  },
  {
    name: '1v1.private',
    path: '/1v1/private',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Private.vue'))
  },
  {
    name: '1v1.sprint',
    path: '/1v1/sprint',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Sprint.vue'))
  },
  {
    name: '1v1.semester',
    path: '/1v1/semester',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Semester.vue'))
  },
  {
    name: '1v1.ace',
    path: '/1v1/ace',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Ace.vue'))
  },
  {
    name: '1v1.courses.show',
    path: '/1v1/courses/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/1v1/Course.vue'))
  }
]
