<template>
  <div v-if="show" class="modal-mask">
    <div class="rounded px-1 py-2 pb-0 mt-4" :class="showQrcode ? 'px-4' : ''">
      <div v-if="!showQrcode" class="rounded mt-4 cursor-pointer" @click="showQrcode = true">
        <img class="w-100" :src="CouponImg">
      </div>
      <div v-else class="rounded text-center bg-white mx-auto py-2">
        <div class="rounded border-gray-100 border mx-auto mb-05">
          <img width="200" :src="qrcode" alt="qrcode">
        </div>
        <div class="text-gray-500 text-sm">
          请联系狮子头兑换优惠券使用哦~
        </div>
      </div>
    </div>
    <div v-if="showQrcode" class="text-center mt-2">
      <img
        :src="requireUrl('/images/coupon-modal-close.png')"
        height="40"
        width="40"
        @click="show = false"
        alt=""
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import CouponImg from '@/assets/images/6-year-coupon.png'
import { requireUrl } from '@/utils/helpers'
import { useStore } from 'vuex'
import { getLv3Qrcode } from '@/api/common'

const store = useStore()
const show = ref(false)
const showQrcode = ref(false)
const qrcode = ref('')

const user = computed(() => store.getters['auth/currentUser'])

watch(
  () => user.value,
  () => {
    if (user.value) {
      checkAndSetQrcode()
    }
  },
  { immediate: true }
)

const checkAndSetQrcode = async () => {
  const { data } = await getLv3Qrcode()

  qrcode.value = data.url

  if (data.is_show) {
    show.value = true
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-header {
  position: absolute;
  width: 80%;
  margin: -80px auto 0;
  z-index: 10;
  left: 0;
  right: 0;
  h3 {
    margin-top: 0;
    color: #42b983;
  }
}
.bg-bottom {
  background-size: 100% 100%;
}
.modal-footer {
  position: absolute;
  width: 100%;
  height: 92px;
  left: 0;
  bottom: -24px;
}
.coupons {
  max-height: 300px;
  overflow-y: auto;
}
.van-button--danger {
  background-color: #ff6700;
}
</style>
