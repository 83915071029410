import * as Vue from 'vue'
const routes = [
  {
    name: 'package.show',
    path: '/package/:id',
    component: Vue.defineAsyncComponent(() => import('@/pages/package/Recharge.vue')),
    meta: {
      auth: true
    }
  },
  {
    name: 'package.course.show',
    path: '/package/:id/course',
    component: Vue.defineAsyncComponent(() => import('@/pages/package/Activity.vue'))
  }
]

export default routes
