export const CodeEventEtl = {
  START_WATCH_VIDEO_ONLINE: '0101', // 开始观看直播
  FINISH_WATCH_VIDEO_ONLINE: '0102', // 结束观看直播
  WRITTEN_COMMUNICATION: '0103', // 上课的文字沟通
  START_WATCH_VIDEO: '0201', // 学员进入录播
  FINISH_WATCH_VIDEO: '0202', // 学员退出录播
  WECHAT_LOGIN_PAGE: '0401',
  ACCOUNT_LOGIN_PAGE: '0402',
  VERIFICATION_LOGIN: '0403',
  LEARNING_CENTER_CLASS_PAGE: '0404',
  LEARNING_CENTER_1V1_PAGE: '0405',
  LEARNING_CENTER_CAREER_PAGE: '0406',
  STUDY_DETAILS_PAGE: '0408',
  FEEDBACK_PAGE: '0409',
  SATISFACTION_QUESTIONNAIRE_COLLECTION_PAGE: '0410',
  LIVE_BROADCAST_PAGE: '0411',
  VIDEO_PLAYBACK_PAGE: '0412',
  BASIC_INFORMATION_PAGE: '0416',
  ACCOUNT_SETTINGS_PAGE: '0417',
  PURCHASE_RECORD: '0418',
  MEMBER_CENTER_PAGE: '0419',

  COURSE_BUSINESS_TYPE: '0704', // 课程业务类型选择
  COURSE_SEARCH: '0705', // 通过搜索栏搜索课程
  ENTER_COURSE_DETAIL: '0708', // 选择课程卡片进入课程详情
  LEARNING_CONTENT: '0710', // 选择学习内容
  OPEN_QUESTIONNAIRE: '0604', // 用户打开用户满意度问卷
  SUBMIT_QUESTIONNAIRE: '0605', // 用户完成问卷
  READ_COURSEWARE: '0606', // 阅读课件
  DOWNLOAD_COURSEWARE: '0607', // 课件下载

  ACTION_EVALUATION_GUIDANCE: '0801', // 问卷 - 活动引导页操作
  ONE_STATIC_PAGE_BUTTON: '0801', // 1v1 静态页面按钮点击

  ACTION_BUTTON: '0801'
}

export const CodeTerminal = {
  WEB: 'A01',
  MOBILE: 'A02',
  H5: 'A03' // 小程序
}

export const CodeBusiness = {
  WEB: 'A0101',
  MOBILE: 'A0201',
  WebEvaluationGuidance: 'A0103',
  MobileEvaluationGuidance: 'A0203',
  MobileButtonClickWith1v1: 'A0205',
  TeacherDay: 'A0312',
  WorryFree: 'A0316',
}

export const TypePostETLCourseType = {
  CLASS: 1,
  ONE_BY_ONE: 2,
  CAREER: 3
}

export const TypePostETLContent = {
  TEXT: 1,
  EMOJI: 2,
  EMOJI_TEXT: 3,
  IMAGE: 4
}

export const EntranceSignETL = {
  TencentLive: 1,
  Record: 2,
  Zoom: 5,
  Agora: 6,
  HiLink: 8
}
