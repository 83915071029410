<template>
  <van-dialog
    class="pb-2 w-75"
    v-model:show="showDialog"
    title="账号存在风险"
    :show-confirm-button="false"
    :close-on-click-overlay="false"
    style="border-radius: 15px"
    @close="onClose"
  >
    <div class="px-2 pt-2 pb-2">
      <p
        v-for="text in tips"
        class="mt-0"
        :key="text"
        style="text-indent: 2rem"
      >
        {{ text }}
      </p>
    </div>
    <div class="text-center text-13 mb-0">
      <button
        class="px-3 py-1 mr-3 bg-yellow rounded-20px border-yellow mx-auto"
        @click="onSubmit"
      >
        我知道了
      </button>
    </div>
  </van-dialog>
</template>

<script>
import { $on, $off, $once, $emit } from '@/utils/gogocodeTransfer'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showDialog: false,
  }),
  computed: {
    ...mapGetters('auth', {
      userBan: 'userBan',
    }),
  },
  watch: {
    show(value) {
      this.showDialog = value
    },
  },
  mounted() {
    this.showDialog = this.show
  },
  methods: {
    ...mapActions('auth', {
      setUserBan: 'setUserBan',
    }),

    onSubmit() {
      this.setUserBan({
        type: 0,
        time: null,
      })
    },

    onClose() {
      $emit(this, 'close')
    },
  },
  emits: ['close'],
}
</script>

<style lang="scss" scoped>
.van-dialog__header {
  font-size: 18px;
}
</style>
